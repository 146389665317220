import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl-next';

import { Stack, Box, Text, xcss, Inline } from '@atlaskit/primitives';
import AlignTextLeftIcon from '@atlaskit/icon/core/align-text-left';
import { token } from '@atlaskit/tokens';
import Avatar from '@atlaskit/avatar';

import { useAiBulkNavigationGotoPage } from './providers/AiBulkNavigationContextProvider';
import { useSetCurrentIssueId } from './providers/BulkCreateContextProvider';
import type { IssueType, IssueObject } from './providers/BulkCreateContextProvider';
import { BulkCreateIssueCardToolBar } from './BulkCreateIssueCardToolBar';
import { messages } from './messages';

const cardStyles = xcss({
	backgroundColor: 'elevation.surface.raised',
	boxShadow: 'elevation.shadow.raised',
	borderRadius: '4px',
	cursor: 'pointer',
	padding: 'space.100',
	margin: 'space.025',
});

const stackStyles = xcss({
	minHeight: '80px',
});

const issueTypeInlineStyles = xcss({
	minHeight: '32px',
});

type BulkCreateIssueCardProps = {
	issue: IssueObject;
	issueType: IssueType;
};

// Used for both AI and non-AI bulk create
export const BulkCreateIssueCard = ({
	issue: { id: issueId, summary, description },
	issueType,
}: BulkCreateIssueCardProps) => {
	const { formatMessage } = useIntl();

	const goToPage = useAiBulkNavigationGotoPage();
	const setCurrentIssueId = useSetCurrentIssueId();

	const [showToolBar, setShowToolBar] = useState(false);
	const onIssueClick = useCallback(() => {
		void goToPage('edit_issue');
		void setCurrentIssueId(issueId);
	}, [goToPage, issueId, setCurrentIssueId]);

	const onMouseEnter = useCallback(() => void setShowToolBar(true), [setShowToolBar]);
	const onMouseLeave = useCallback(() => void setShowToolBar(false), [setShowToolBar]);

	return (
		<Box
			xcss={cardStyles}
			onClick={onIssueClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Stack xcss={stackStyles} space="space.200" spread="space-between">
				<Inline alignBlock="center" space="space.100">
					<Text maxLines={2}>{summary}</Text>
				</Inline>
				<Inline alignBlock="center" spread="space-between">
					<Inline alignBlock="center" space="space.100">
						<Inline alignBlock="center" space="space.050" xcss={issueTypeInlineStyles}>
							<Avatar appearance="square" size="xsmall" src={issueType.iconUrl} />
							<Text color="color.text.subtlest" weight="medium" size="small">
								{issueType.name}
							</Text>
						</Inline>
						{description && (
							<AlignTextLeftIcon
								label={formatMessage(messages.issueCardDescriptionIconLabel)}
								color={token('color.icon')}
								testId="bulk-create-issue-card-description-icon"
							/>
						)}
					</Inline>
					{showToolBar && <BulkCreateIssueCardToolBar issueId={issueId} />}
				</Inline>
			</Stack>
		</Box>
	);
};
